/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($, window, document, undefined) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
        //
        // mobile menu actions
        var $slide = $('#menu-slide');
        var $top = $('#main-header');
        $('#menu-slide a.menu-link').on('click', function(e) {
          e.preventDefault();
          var $restaurant = $('#' + $(this).data('restaurant-id') );

          // need to scroll to top 
          $('html, body').animate({
            scrollTop: $top.offset().top
          }, 700);

          if ($restaurant) {
            $slide.toggleClass('expanded');  
            // copy the contents, put in 'the-menu-target'
            var contents = $restaurant.clone(true);
            $('#the-menu-target').empty().append(contents);
          }
          return false;
        });

        // toggle showing content & mobile menu, or showing the guide
        //
        var $toggle_content = $('div.toggle-content');
        var $toggle_menu_guide = $('div.toggle-menu-guide');
        var $toggle_on_mobile_buttons = $('.mobile-menu-buttons .toggle-on');
        var $toggle_off_mobile_button = $('.mobile-menu-buttons .toggle-off');

        $('.mobile-menu-buttons .button-toggle-content-guide').on('click', function(e) {
          e.preventDefault();
          $toggle_content.toggleClass('none');
          $toggle_menu_guide.toggleClass('none');
          $toggle_on_mobile_buttons.toggleClass('none');
          $toggle_off_mobile_button.toggleClass('none');
          return false;
        });
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery, window, document); // Fully reference jQuery after this point.
